@layer base {
  /* Firefox: applies to all scrollable elements */
  * {
    scrollbar-width: thin;
    scrollbar-color: hsl(var(--muted-opaque)) transparent;
  }

  /* WebKit browsers */
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: hsl(var(--muted-opaque));
    border-radius: 9999px;
    border: 2px solid transparent;
    background-clip: content-box;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: hsl(var(--muted-opaque));
  }
}
